<template>
  <div v-if="isAuthenticated">

    <div class="grid-x grid-padding-x">
      <div class="cell callout success"><h3><i class="fi-thumbnails"></i>&nbsp;&nbsp;&nbsp;Organisationen</h3></div>
    </div>

    <div class="grid-x grid-padding-x grid-padding-y">

      <div class="cell">
        <md-table v-model="searched" md-sort="name" md-sort-order="asc" md-card md-fixed-header
                  @md-selected="onSelect" :style="tableStyle">
          <md-table-toolbar>
            <div class="md-toolbar-section-start grid-x grid-padding-x" style="padding-top: 0.5rem;">
              <div class="cell large-3 medium-5 small-9">
                <p class="md-title ai-number">{{ searched.length }}&nbsp;Organisationen</p>
                <p v-if="organizations.length - searched.length > 0"
                   style="color: crimson; margin: 0; padding-left: 10px;">{{ organizations.length - searched.length }}
                  nicht sichtbar
                  <md-tooltip style="font-size: medium; height: auto; white-space: pre-wrap;" md-direction="bottom"
                              md-delay="500">
                    <b>Warum nicht sichtbar?</b><br><br>
                    <span>Durch setzen von Filtern wird die Liste eingeschränkt.</span><br><br>
                    <span>Klicken Sie auf <md-button class="md-icon-button md-raised md-primary"
                                                     style="background: cornflowerblue; margin-bottom: 10px">
                                  <md-icon style="color: white">select_all</md-icon>
                                </md-button> um alle Einträge zu sehen.</span>
                  </md-tooltip>
                </p>
                <p v-else style="color: seagreen; margin: 0; padding-left: 10px;">
                  Alle sichtbar
                </p>
              </div>
              <div class="cell large-1 medium-1 small-1">
                <vue-simple-spinner v-if="sending"></vue-simple-spinner>
                <div v-else>
                  <div class="md-layout">
                    <div class="md-layout-item">
                      <md-button class="md-icon-button md-raised md-primary" style="background: cornflowerblue"
                                 @click="onShowAllOrganizations">
                        <md-icon>select_all</md-icon>
                        <md-tooltip md-direction="bottom" style="font-size: medium;">
                          Alle <b>{{ organizations.length }}</b> Organisationen anzeigen
                        </md-tooltip>
                      </md-button>
                    </div>
                  </div>
                </div>
              </div>

              <div class="cell large-8 medium-6 small-12">
                <md-field md-clearable class="md-toolbar-section-end">
                  <md-input placeholder="Suche Name der Organisation (Träger) ..." v-model="search"
                            @input="searchOnTable"/>
                </md-field>
              </div>

              <div class="cell large-4 medium-6 hide-for-small-only"></div>
              <div class="cell large-4 medium-6">
                <md-field>
                  <label>Merkmale</label>
                  <md-select v-model="selectedOrganizationAttributes" :disabled="sending" @md-selected="onSelectFilter"
                             multiple>
                    <md-option v-for="attribute of labels.organizationAttributes" :key="attribute" :value="attribute">
                      {{ labels.organizationAttribute[attribute] }}
                    </md-option>
                  </md-select>
                </md-field>
              </div>

              <div class="cell hide-for-large medium-6 hide-for-small-only"></div>
              <div class="cell large-4 medium-6">
                <md-field>
                  <label>Gebuchte Module</label>
                  <md-select v-model="selectedOrganizationModuleTypes" :disabled="sending" @md-selected="onSelectFilter"
                             multiple>
                    <md-option v-for="type of labels.moduleTypes" :key="type" :value="type">
                      {{ labels.moduleType[type] }}
                    </md-option>
                  </md-select>
                </md-field>
              </div>

              <div class="cell large-4 medium-6 hide-for-small-only"></div>
              <div class="cell large-4 medium-6">
                <md-field>
                  <label>Organisationsform</label>
                  <md-select v-model="selectedOrganizationTypes" :disabled="sending" @md-selected="onSelectFilter"
                             multiple>
                    <md-option v-for="type of labels.organizationTypes" v-bind:value="type" :key="type">
                      {{ labels.organizationType[type] }}
                    </md-option>
                  </md-select>
                </md-field>
              </div>
              <div class="cell large-4 hide-for-medium-only hide-for-small-only"></div>

              <div class="cell large-4 medium-6 hide-for-small-only"></div>
              <div class="cell large-8 medium-6">
                <div class="grid-x grid-padding-x">
                  <div class="cell" style="padding-bottom: 15px;">
                    <md-button v-if="isSage" @click="createOrganizationDialog"
                               class="md-icon-button md-raised md-primary">
                      <md-icon>add</md-icon>
                      <md-tooltip>Neue Organisation anlegen</md-tooltip>
                    </md-button>
                    <md-button v-if="isAdmin" @click="editOrganizationDialog"
                               :disabled="!selected || sending"
                               class="md-icon-button md-raised md-third">
                      <md-icon>edit</md-icon>
                      <md-tooltip>Organisation bearbeiten</md-tooltip>
                    </md-button>
                    <md-button v-if="isAdmin" @click="deleteOrganizationDialog"
                               :disabled="!selected || sending"
                               class="md-icon-button md-raised md-accent">
                      <md-icon>delete</md-icon>
                      <md-tooltip>Organisation löschen</md-tooltip>
                    </md-button>
                    <md-button v-if="isSage" @click="onTakeOverIdentity"
                               :disabled="!selected || sending"
                               class="md-icon-button md-raised md-forth">
                      <md-icon>fingerprint</md-icon>
                      <md-tooltip>Identität übernehmen</md-tooltip>
                    </md-button>
                  </div>
                </div>
              </div>
            </div>

          </md-table-toolbar>

          <md-table-empty-state v-if="search"
                                md-label="Keine Organisation gefunden"
                                :md-description="`Keine Organisation mit dem Suchbegriff '${search}' gefunden. Versuchen Sie es mit einem anderen Begriff oder legen Sie eine neue Organisation an.`">
          </md-table-empty-state>

          <md-table-row slot="md-table-row" slot-scope="{ item }" :class="getClass(item)" md-selectable="single">
            <md-table-cell md-label="Kundennummer" md-sort-by="referenceNumber" md-numeric>{{ item.customerNo }}
            </md-table-cell>
            <md-table-cell md-label="Name" md-sort-by="name">{{ item.name }}</md-table-cell>
            <md-table-cell md-label="Organisationsform" md-sort-by="organizationType">{{
                labels.organizationType[item.organizationType]
              }}
            </md-table-cell>
            <md-table-cell md-label="Adresse" md-sort-by="address.street">
              <AddressView :address="item.address" :raw="true"/>
            </md-table-cell>
            <md-table-cell md-label="Kontakt" md-sort-by="contact.email">
              <ContactView :contact="item.contact" :raw="true"/>
            </md-table-cell>
            <md-table-cell md-label="Leitung" md-sort-by="responsiblePerson.lastname">
              <PersonView :person="item.responsiblePerson" :raw="true"/>
            </md-table-cell>
            <md-table-cell md-label="Einrichtungen" md-sort-by="facilitiesString"
                           v-html="item.facilitiesString">
            </md-table-cell>

            <md-tooltip v-if="item.testPhaseActive || item.testPhaseExpired"
                        style="font-size: medium; height: auto; width: 400px; white-space: pre-wrap;"
                        md-direction="top">Testphase aktiv: <b>{{ booleanText(item.testPhaseActive) }}</b><br>Testphase
              bis: <b>{{
                  item.testPhaseUntil
                      | moment("DD.MM.YYYY")
                }}</b><br>Testphase abgelaufen: <b>{{ booleanText(item.testPhaseExpired) }}</b>
            </md-tooltip>
          </md-table-row>
        </md-table>
      </div>
    </div>

    <md-dialog :md-click-outside-to-close="false" :md-active.sync="showDeleteOrganizationDialog">
      <div v-if="selected" class="grid-x grid-padding-x grid-padding-y">
        <md-dialog-title><i class="fi-trash"></i>&nbsp;&nbsp;&nbsp;Organisation löschen - Kundennummer
          {{ selected.customerNo }}
        </md-dialog-title>
        <div class="cell">
          <md-content>Soll die Organisation <b>{{ selected.name }}</b> wirklich gelöscht werden?</md-content>
          <br>
          <span>
            <md-radio v-model="deleteCompletely" value="false" class="md-primary">
              Nur Organisationseinheit <i>(Es dürfen keine Einrichtungen mehr existieren)</i>
            </md-radio>
            <md-radio v-if="isSage" v-model="deleteCompletely" value="true" class="md-primary">
              Vollständig <i>(Alle Kinder, Einrichtungen und die Organisation)</i>
            </md-radio>
          </span>
          <md-progress-bar md-mode="indeterminate" v-if="sending"/>
        </div>
        <div class="cell text-center">
          <md-checkbox v-model="absolutelySure" :disabled="sending">Ich bin mir absolut sicher!</md-checkbox>
        </div>
        <div class="cell text-center">
          <button v-if="isAdmin" @click="onDeleteOrganization" class="button alert"
                  :disabled="sending || !absolutelySure || deleteCompletely === ''"><i class="fi-trash"></i>&nbsp;&nbsp;&nbsp;Ja,
            <span v-if="deleteCompletely === 'true'"><b>Organisation</b> jetzt <b>vollständig</b></span>
            <span v-else>Organisationseinheit jetzt</span> löschen
          </button>
          <button class="button success" style="margin-left: 1rem;"
                  @click="onCloseDeleteOrganizationDialog"
                  type="button"
                  :disabled="sending">
            <i class="fi-x"></i>&nbsp;&nbsp;&nbsp;Nein, lieber nicht
          </button>
        </div>
        <button class="close-button" type="button" @click="onCloseDeleteOrganizationDialog">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
    </md-dialog>

    <md-dialog :md-click-outside-to-close="false" :md-active.sync="showTakeoverIdentityDialog">
      <div v-if="selected" class="grid-x grid-padding-x grid-padding-y">
        <md-dialog-title>
          <md-icon>fingerprint</md-icon>&nbsp;&nbsp;&nbsp;Identität übernehmen
        </md-dialog-title>
        <div class="cell">
          <md-content>Soll die Identität für Organisation <b>{{ selected.name }}</b> wirklich übernommen werden?
          </md-content>
        </div>
        <div class="cell text-center">
          <div v-if="isSage" @click="takeOverIdentity" class="button alert">
            <md-icon style="height: 12px;">fingerprint</md-icon>&nbsp;&nbsp;&nbsp;Ja, Identität jetzt übernehmen
          </div>
          <button class="button success" style="margin-left: 1rem;"
                  @click="showTakeoverIdentityDialog = false;"
                  type="button"
                  :disabled="sending">
            <i class="fi-x"></i>&nbsp;&nbsp;&nbsp;Nein, lieber nicht
          </button>
        </div>
        <button class="close-button" type="button" @click="showTakeoverIdentityDialog = false;">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
    </md-dialog>

    <EditOrganizationDialog :showDialog="showEditOrganizationDialog" :organization="selectedOrganization"
                            :mode="dialogMode"
                            @updateOrganizationSuccess="updateOrganizationSuccess"
                            @closeEditOrganizationDialog="showEditOrganizationDialog = false"></EditOrganizationDialog>
  </div>
</template>

<script>
import Spinner from 'vue-simple-spinner'

import OrganizationService from '../services/OrganizationService';
import UserService from '../services/UserService';
import HttpErrorHandler from '../services/HttpErrorHandler';

import EditOrganizationDialog from "../components/dialogs/EditOrganizationDialog"
import AddressView from "../components/views/AddressView";
import ContactView from "../components/views/ContactView";
import PersonView from "../components/views/PersonView";

import Organization from "../entities/Organization"
import jQuery from "jquery";

const toLower = text => {
  return text.toString().toLowerCase();
};

const searchByName = (items, term) => {
  if (term) {
    return items.filter(item => toLower(item.name).includes(toLower(term)));
  }

  return items;
};

const filterOrganizationsByAttributes = (items, terms) => {
  if (terms) {
    if (terms.length <= 0) {
      return items;
    }
    return items.filter(item => {
      if (terms.includes('PAYING_CUSTOMER') && item.payingCustomer) {
        return true;
      } else if (terms.includes('PARTNER') && item.partner) {
        return true;
      } else if (terms.includes('HAS_FACILITIES') && item.facilities.length > 0) {
        return true;
      } else if (terms.includes('TEST_PHASE_ACTIVE') && item.testPhaseActive && !item.testPhaseExpired) {
        return true;
      } else if (terms.includes('TEST_PHASE_EXPIRED') && item.testPhaseExpired) {
        return true;
      } else {
        return (terms.length <= 0);
      }
    });
  }

  return items;
};

const filterOrganizationsByModuleTypes = (items, terms) => {
  if (terms) {
    if (terms.length <= 0) {
      return items;
    }
    return items.filter(item => {

      let modulesFound = [];
      for (let term of terms) {
        let found = false;
        for (let module of item.allowedModules) {
          if (toLower(module).includes(toLower(term))) {
            found = true;
          }
        }

        if (found) {
          modulesFound.push(term);
        }
      }

      if (modulesFound.length === terms.length) {
        return true;
      }
      return false;
    });
  }

  return items;
};

const filterOrganizationsByOrganizationTypes = (items, terms) => {
  if (terms) {
    if (terms.length <= 0) {
      return items;
    }

    return items.filter(item => {
      for (let term of terms) {
        if (toLower(item.organizationType).includes(toLower(term))) {
          return true;
        }
      }
      return false;
    });
  }

  return items;
};

export default {
  name: 'Organizations',
  components: {
    EditOrganizationDialog,
    AddressView,
    ContactView,
    PersonView,
    Organization,
    'vue-simple-spinner': Spinner
  },

  mounted() {
    this.initData();
    this.reloadOrganizations();
  },

  data() {
    return {
      sending: false,
      organizations: [],
      searched: [],
      search: null,
      selected: null,
      selectedOrganization: null,
      showDeleteOrganizationDialog: false,
      showEditOrganizationDialog: false,
      showTakeoverIdentityDialog: false,
      success: false,
      successMsg: '',
      dialogMode: 'update',
      lastOrganization: '',
      deleteCompletely: '',
      absolutelySure: false,

      selectedOrganizationAttributes: [],
      selectedOrganizationModuleTypes: [],
      selectedOrganizationTypes: [],
    }
  },

  methods: {

    booleanText(value) {
      return value ? 'ja' : 'nein';
    },

    getClass: (org) => {
      let cl = '';

      if (org.testPhaseActive && !org.testPhaseExpired) {
        cl = 'test-phase-active';
      } else if (org.testPhaseExpired && org.testPhaseExpired) {
        cl = 'test-phase-expired';
      }

      if (org.id === org.id) {
        return 'md-primary ' + cl;
      } else {
        return cl;
      }
    },

    initData() {
      this.selectedOrganizationAttributes = ['PAYING_CUSTOMER'];
      if (localStorage.getItem(this.user.md5 + '@selectedOrganizationAttributes')) {
        this.selectedOrganizationAttributes = JSON.parse(localStorage.getItem(this.user.md5 + '@selectedOrganizationAttributes'));
      }
      this.selectedOrganizationModuleTypes = ['BASE'];
      if (localStorage.getItem(this.user.md5 + '@selectedOrganizationModuleTypes')) {
        this.selectedOrganizationModuleTypes = JSON.parse(localStorage.getItem(this.user.md5 + '@selectedOrganizationModuleTypes'));
      }
      if (localStorage.getItem(this.user.md5 + '@selectedOrganizationTypes')) {
        this.selectedOrganizationTypes = JSON.parse(localStorage.getItem(this.user.md5 + '@selectedOrganizationTypes'));
      }

      setTimeout(() => {
        jQuery('.md-table-content.md-scrollbar.md-theme-default').removeAttr('style');
      }, 500);
    },

    reloadOrganizations() {
      let maxTries = process.env.VUE_APP_RELOAD_MAX_TRIES;
      const reloadIntervalId = setInterval(() => {
        if (this.dataAvailable) {
          if (this.isSage || this.isAdmin) {
            this.getOrganizations();
          }
          clearInterval(reloadIntervalId);
        } else {
          this.sending = true;
          maxTries--;
          if (maxTries <= 0) {
            this.sending = false;
            clearInterval(reloadIntervalId);
            HttpErrorHandler.handleError(null, this, 'Fehler beim Laden von Benutzer / Organisation / Labels');
          }
        }
      }, 250);
    },


    getOrganizations() {
      this.sending = true;
      OrganizationService.getAllOrganizations()
          .then(response => {
            this.organizations = response.data;
            this.searched = this.organizations;
            this.sending = false;

            for (let i = 0; i < this.organizations.length; i++) {
              this.organizations[i].facilitiesString = '';
              let j = 1;
              for (let facility of this.organizations[i].facilities) {
                this.organizations[i].facilitiesString += '(' + j++ + ') ' + facility.name + '</br>';
              }
            }

            this.searchOnTable();
          })
          .catch(e => {
            HttpErrorHandler.handleError(e, this, 'Fehler beim Laden der Liste aller Organisationen');
            this.sending = false;
          });
    },

    onCloseDeleteOrganizationDialog() {
      this.showDeleteOrganizationDialog = false;
      this.deleteCompletely = '';
      this.absolutelySure = false;
    },

    deleteOrganizationDialog() {
      this.success = false;
      if (this.selected) {
        this.lastOrganization = this.selected.name;
        this.showDeleteOrganizationDialog = true;
      }
    },

    createOrganizationDialog() {
      this.success = false;
      this.dialogMode = 'create';
      this.selectedOrganization = JSON.parse(JSON.stringify(Organization.organization));
      this.showEditOrganizationDialog = true;
    },

    editOrganizationDialog() {
      this.success = false;
      if (this.selected) {
        this.dialogMode = 'update';
        this.selectedOrganization = JSON.parse(JSON.stringify(this.selected));
        if (this.selectedOrganization.address === null) {
          this.selectedOrganization.address = Organization.fullOrganization.address;
        }
        if (this.selectedOrganization.contact === null) {
          this.selectedOrganization.contact = Organization.fullOrganization.contact;
        }
        if (this.selectedOrganization.responsiblePerson === null) {
          this.selectedOrganization.responsiblePerson = Organization.fullOrganization.responsiblePerson;
        }
        this.showEditOrganizationDialog = true;
      }
    },

    updateOrganizationSuccess(updatedOrganization) {
      this.showEditOrganizationDialog = false;

      if (this.dialogMode === 'update') {
        this.$store.commit('successMsg', 'Die Änderungen für Organisation <b>' + updatedOrganization.name + '</b> wurden erfolgreich gespeichert!');
      }
      if (this.dialogMode === 'create') {
        this.$store.commit('successMsg', 'Die Organisation <b>' + updatedOrganization.name + '</b> wurde erfolgreich angelegt!');
      }

      this.success = true;
      this.selected = null;
      this.getOrganizations();
    },

    onDeleteOrganization() {
      if (this.deleteCompletely === 'true') {
        this.deleteOrganizationCompletely();
      } else if (this.deleteCompletely === 'false') {
        this.deleteOrganizationEntity();
      }
    },

    deleteOrganizationEntity() {
      if (this.selected) {
        this.success = false;
        this.sending = true;
        OrganizationService.deleteOrganizationEntity(this.selected.id)
            .then(() => {
              this.sending = false;
              this.$store.commit('successMsg', 'Die Organisationseinheit <b>' + this.lastOrganization + '</b> wurde erfolgreich gelöscht.');
              this.success = true;
              this.search = null;
              this.selected = null;
              this.onCloseDeleteOrganizationDialog();
              this.getOrganizations();
            })
            .catch(e => {
              HttpErrorHandler.handleError(e, this, 'Fehler beim Löschen der Organisationseinheit ' + this.selected.name);
              this.sending = false;
            })
      }
    },

    deleteOrganizationCompletely() {
      if (this.selected) {
        this.success = false;
        this.sending = true;
        OrganizationService.deleteOrganizationCompletely(this.selected.customerNo)
            .then(() => {
              this.sending = false;
              this.$store.commit('successMsg', 'Die Organisation <b>' + this.lastOrganization + '</b> wurde erfolgreich vollständig gelöscht!');
              this.success = true;
              this.search = null;
              this.selected = null;
              this.onCloseDeleteOrganizationDialog();
              this.getOrganizations();
            })
            .catch(e => {
              HttpErrorHandler.handleError(e, this, 'Fehler beim vollständigen Löschen der Organisation ' + this.selected.name);
              this.sending = false;
            })
      }
    },

    onShowAllOrganizations() {
      this.selectedOrganizationAttributes = [];
      this.selectedOrganizationModuleTypes = [];
      this.selectedOrganizationTypes = [];
      this.search = null;
    },

    onSelectFilter() {
      localStorage.setItem(this.user.md5 + '@selectedOrganizationAttributes', JSON.stringify(this.selectedOrganizationAttributes));
      localStorage.setItem(this.user.md5 + '@selectedOrganizationModuleTypes', JSON.stringify(this.selectedOrganizationModuleTypes));
      localStorage.setItem(this.user.md5 + '@selectedOrganizationTypes', JSON.stringify(this.selectedOrganizationTypes));
      this.searchOnTable();
    },

    searchOnTable() {
      this.searched = this.organizations;
      this.searched = filterOrganizationsByAttributes(this.searched, this.selectedOrganizationAttributes);
      this.searched = filterOrganizationsByModuleTypes(this.searched, this.selectedOrganizationModuleTypes);
      this.searched = filterOrganizationsByOrganizationTypes(this.searched, this.selectedOrganizationTypes);
      this.searched = searchByName(this.searched, this.search);
    },

    onSelect(item) {
      if (item) {
        this.selected = JSON.parse(JSON.stringify(item));
        this.selectedOrganization = JSON.parse(JSON.stringify(this.selected));
      } else {
        this.selected = null;
        this.selectedOrganization = null;
      }
    },

    onTakeOverIdentity() {
      if (this.selected && this.isSage) {
        this.selectedOrganization = JSON.parse(JSON.stringify(this.selected));
        this.showTakeoverIdentityDialog = true;
      }
    },

    takeOverIdentity() {
      this.sending = true;
      this.showTakeoverIdentityDialog = false;
      UserService.takeOverIdentity(this.selectedOrganization.customerNo)
          .then(response => {
            this.$store.commit('userInfo', response.data);
            this.sending = false;
            this.$store.commit('successMsg', 'Identität der Organisation <b>' + this.selectedOrganization.customerNo + '</b> wurde erfolgreich übernommen.');
            this.success = true;
            this.getOrganizationInfo();
          })
          .catch(e => {
            HttpErrorHandler.handleError(e, this, 'Fehler beim Übernehmen der Identität der Organisation <b>' + this.selectedOrganization.customerNo + '</b>');
            this.sending = false;
          })
    },

    getOrganizationInfo() {
      this.sending = true;
      OrganizationService.getOrganizationInfo()
          .then(response => {
            this.$store.commit('organizationInfo', response.data);
            this.$store.commit('availableFacilities');

            // Create a unique user id for sage when taking over others identity.
            // This is needed for a unique md5 calculation in the user object for storing parameter selections in the local storage uniquely.
            this.user.id = this.user.id + response.data.customerNo;
            this.$store.commit('userInfo', this.user);

            this.sending = false;
            this.success = false;
            setTimeout(() => {
              this.$store.commit('successMsg', 'Daten der Organisation <b>' + this.selectedOrganization.customerNo + '</b> wurden erfolgreich nachgeladen.');
              this.success = true;
            }, 500);
            this.$router.push('/Kinder');
          })
          .catch(e => {
            HttpErrorHandler.handleError(e, this, 'Fehler beim Laden der Daten für Organisation <b>' + this.selectedOrganization.customerNo + '</b>');
          });
    },
  },

  computed: {

    tableStyle() {
      return this.$store.getters.sage ? 'min-height: 720px; height: calc(100vh - 345px); overflow-x: hidden' : 'min-height: 620px; height: calc(100vh - 349px); overflow-x: hidden'
    },

    dataAvailable() {
      return this.isAdmin;
    },

    labels() {
      return this.$store.getters.labels
    },

    isAuthenticated() {
      return this.$store.getters.authenticated
    },

    user() {
      return this.$store.getters.user;
    },

    isSage() {
      return this.$store.getters.sage
    },

    isAdmin() {
      return this.$store.getters.admin
    }
  }
}
</script>

<style lang="scss" scoped>
i {
  color: black;
}

.md-radio {
  margin: 8px 8px 8px 0;
}

.md-tooltip {
  font-size: medium;
  height: auto;
  white-space: normal;
}

.test-phase-active {
  color: darkseagreen;
}

.test-phase-expired {
  color: coral;
}
</style>
